export enum ETipoNotificacaoEmail {
    Padrao = 1,
    NovasContas = 2,
    BoasVindas = 3,
    AtivacaoConta = 4,
    RedefinirSenha = 5,
    Contatos = 6,
    Newsletter = 7,
    NovosPedidos = 8,
    PedidoCancelado = 9,
    PedidoAtualizado = 10,
}
